import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import first from 'lodash/first';
import get from 'lodash/get';
import { GET_ALL_PAGED_CERTIFICATES, GET_PAGED_LEARNING_PATHS, GET_PAGED_USERS } from './graphql';
import { GET_ALL_MARKETS } from 'routes/Activities/OnlineClassroom/context/graphql';
import { getWorkSector, setWorkSector } from 'utils/localStorage';
import useEngageDashTabs from '../hooks/useEngageDashTabs';
import { Outlet } from 'react-router-dom';

export const ENGAGE_ADMIN_TABS = {
  LEARNING_CAMPAIGNS: 'LearningCampaigns',
  LEARNING_PATHS: 'LearningPaths',
  INF_LEARNING_TEMPLATES: 'InfLearningTemplates',
  INF_BADGE_TEMPLATE: 'InfBadgeTemplate',
  USER_REPORT: 'user_report',
};

export const PER_PAGE = 15;

export const buildFilterObj = ({ currentMarket, categoryFilters }) => {
  let filters = {};

  filters.market = currentMarket;

  if (categoryFilters && categoryFilters.length > 0) {
    filters.templateCategories = categoryFilters.map(category => category.name);
  }
  return filters;
};

export const TrainingAdminContext = React.createContext({});

export function TrainingAdminProvider({ children }) {
  // To be shared between Learning Paths and Learning Campaigns
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const engageDashTabs = useEngageDashTabs();

  // Learning Paths state values
  const [learningPaths, setLearningPaths] = useState([]);

  // User Templates state values
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [userFilter, setUserFilter] = useState('');

  const [certificates, setCertificates] = useState([]);
  const [totalCertificates, setTotalCertificates] = useState(0);

  // Filters
  const [page, setPage] = useState(0);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [currentMarket, setCurrentMarket] = useState(getWorkSector());
  const [markets, setMarkets] = useState([]);

  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [learningCampaignFilter, setLearningCampaignFilter] = useState([]);
  const [moduleFilter, setModuleFilter] = useState('');

  useEffect(() => {
    setLearningPaths([]);
    setPage(0);
  }, [engageDashTabs.tabId]);

  useEffect(() => {
    setPage(0);
    setLoading(true);
    setHasMore(true);

    if (getWorkSector() !== currentMarket) {
      setWorkSector(currentMarket);
    }
  }, [categoryFilters, currentMarket]);

  useQuery(GET_PAGED_LEARNING_PATHS, {
    skip: engageDashTabs.tabId !== ENGAGE_ADMIN_TABS.LEARNING_PATHS,
    fetchPolicy: 'no-cache',
    variables: {
      page,
      perPage: PER_PAGE,
    },
    onCompleted: data => {
      setLoading(false);
      setError(false);
      const newLearningPaths = get(data, 'pagedLearningPaths.learningPaths', []);

      if (newLearningPaths.length > 0) {
        setHasMore(true);

        if ((page + 1) * PER_PAGE >= get(data, 'pagedLearningPaths.learningPathsCount', 0)) {
          setHasMore(false);
        }

        setLearningPaths(learningPaths.concat(newLearningPaths));
      } else {
        setHasMore(false);
      }
    },
    onError: () => {
      setError(true);
      setLoading(false);
    },
  });

  useQuery(GET_PAGED_USERS, {
    skip: engageDashTabs.tabId !== ENGAGE_ADMIN_TABS.USER_REPORT,
    fetchPolicy: 'no-cache',
    variables: {
      page,
      perPage: PER_PAGE,
      q: userFilter,
    },
    onCompleted: function({ pagedUsers }) {
      setUsers(pagedUsers.users);
      setTotalUsers(pagedUsers.usersCount);
    },
    onError: () => {
      setError('There was an error while loading users. Please try again.');
    },
  });

  useQuery(GET_ALL_PAGED_CERTIFICATES, {
    skip: engageDashTabs.tabId !== ENGAGE_ADMIN_TABS.USER_REPORT,
    fetchPolicy: 'no-cache',
    variables: {
      page: page,
      perPage: PER_PAGE,
      startDateFilter: startDateFilter,
      endDateFilter: endDateFilter,
      learningCampaignFilter: learningCampaignFilter.map(e => e.name),
      moduleFilter: moduleFilter,
      userFilter: userFilter,
    },
    onCompleted: ({ allPagedCertificates }) => {
      setError(null);
      if (allPagedCertificates && allPagedCertificates.count > 0) {
        setCertificates(allPagedCertificates.certificates);
        setTotalCertificates(allPagedCertificates.count);
      } else {
        setCertificates([]);
        setTotalCertificates(0);
      }
    },
    onError: () => setError('There was an error while loading certificates. Please try again.'),
  });

  // Markets
  useQuery(GET_ALL_MARKETS, {
    skip:
      engageDashTabs.tabId !== ENGAGE_ADMIN_TABS.INF_LEARNING_TEMPLATES &&
      engageDashTabs.tabId !== ENGAGE_ADMIN_TABS.INF_BADGE_TEMPLATE,
    onCompleted: ({ markets }) => {
      setMarkets(markets);
      if (!currentMarket && markets.length > 0) {
        setCurrentMarket(first(markets).id);
      }
    },
  });

  return (
    <TrainingAdminContext.Provider
      value={{
        loading,
        error,
        page,
        hasMore,
        engageDashTabs,
        learningPaths,
        markets,
        users,
        totalUsers,
        currentMarket,
        categoryFilters,
        userFilter,
        certificates,
        totalCertificates,
        startDateFilter,
        endDateFilter,
        learningCampaignFilter,
        moduleFilter,
        setters: {
          setLoading,
          setPage,
          setCurrentMarket,
          setCategoryFilters,
          setUserFilter,
          setCertificates,
          setTotalCertificates,
          setStartDateFilter,
          setEndDateFilter,
          setLearningCampaignFilter,
          setModuleFilter,
        },
      }}
    >
      <Outlet />
    </TrainingAdminContext.Provider>
  );
}
