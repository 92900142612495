import React, { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

export const QuizContext = createContext({});

export function QuizProvider() {
  const [currentQuizAttempt, setCurrentQuizAttempt] = useState(null);

  return (
    <QuizContext.Provider
      value={{
        currentQuizAttempt,
        setCurrentQuizAttempt,
      }}
    >
      <Outlet />
    </QuizContext.Provider>
  );
}
