import React from 'react';
import PropTypes from 'prop-types';
import { getUserData } from 'utils/auth';

export function useBadgeGuard() {
  const { memberAgency } = getUserData();
  return memberAgency?.hasBadges;
}

function BadgeGuard({ children }) {
  const hasBadges = useBadgeGuard();
  return hasBadges ? children : null;
}

BadgeGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BadgeGuard;
