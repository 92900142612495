import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Col, Row } from 'reactstrap';
import Loader from 'components/Loader';
import useLearningCampaignsQuery from 'hooks/queries/useLearningCampaignsQuery';
import { MainSectionInner as FilterSection } from 'routes/EngageAdmin/LearningCampaign/components/common';
import { NoResultsFound, ResultsListHeader, ResultsTab } from '../../commonStyledElements';

// sub-components
import CampaignSortFilter from './components/CampaignSortFilter';
import CampaignEmailFilter from './components/CampaignEmailFilter';
import ResultItem from './components/ResultItem';

const campaignTabs = [
  { id: 'ActiveCampaigns', text: 'Active Campaigns' },
  { id: 'InactiveCampaigns', text: 'Inactive Campaigns' },
];

function LearningCampaigns() {
  const [currentTab, setCurrentStatusTab] = useState(campaignTabs[0].id);
  const [page, setPage] = useState(0);
  const [sorting, setSorting] = useState({});
  const [filterEmail, setFilterEmail] = useState('');

  const { data, loading, fetchMore } = useLearningCampaignsQuery({ page, currentTab, sorting, filterEmail });

  const learningCampaigns = data?.pagedLearningCampaigns?.learningCampaigns || [];
  const hasMore = learningCampaigns.length < data?.pagedLearningCampaigns?.learningCampaignCount;

  const handleTabChange = tabId => {
    setPage(0);
    setCurrentStatusTab(tabId);
  };

  const loadMore = () => {
    fetchMore({
      variables: { page: page + 1 },
    }).then(() => {
      setPage(page + 1);
    });
  };

  return (
    <div>
      <ResultsListHeader>
        {campaignTabs.map(statusTab => (
          <ResultsTab
            key={statusTab.id}
            active={currentTab === statusTab.id}
            onClick={() => handleTabChange(statusTab.id)}
            tabIndex='0'
          >
            {statusTab.text}
          </ResultsTab>
        ))}
      </ResultsListHeader>

      <FilterSection>
        <Row>
          <Col>
            <CampaignSortFilter disabled={loading} setSorting={setSorting} />
          </Col>
          <Col>
            <CampaignEmailFilter disabled={loading} setFilterEmail={setFilterEmail} />
          </Col>
        </Row>
      </FilterSection>

      <hr />

      <InfiniteScroll
        loadMore={() => !loading && loadMore()}
        hasMore={hasMore && !loading}
        loader={<Loader key='loader' />}
      >
        {!loading && learningCampaigns.length ? (
          learningCampaigns.map(learningCampaign => (
            <ResultItem key={learningCampaign.id} learningCampaign={learningCampaign} />
          ))
        ) : (
          <NoResultsFound>No Learning Campaigns found</NoResultsFound>
        )}
      </InfiniteScroll>
    </div>
  );
}

LearningCampaigns.propTypes = {};

export default LearningCampaigns;
