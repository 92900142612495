import 'react-app-polyfill/stable';
import 'fast-text-encoding/text.min.js';
import 'core-js';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/stable';
import 'core-js/features/string/repeat';
import 'raf/polyfill';
import 'regenerator-runtime';
import 'es6-promise';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'contexts/authContext';
import fetch from 'isomorphic-fetch';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import App from './App';
import { isAuthenticated, getToken } from 'utils/auth';
import getAppFragments from 'utils/fragments/index';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

/**
 * @param {string} resultsField - the array field to be merged
 * @return {function(*, *, {readField: *}): *}
 */
const makePaginationMerge = resultsField => {
  return (existing, incoming, { readField }) => {
    const incomingArray = incoming?.[resultsField] ?? [];
    const incomingIds = incomingArray.map(x => readField('id', x));
    const filteredExistingArray = (existing?.[resultsField] ?? []).filter(
      x => !incomingIds.includes(readField('id', x))
    );

    return {
      ...incoming, // sets `__typename` and the count field
      [resultsField]: [...filteredExistingArray, ...incomingArray],
    };
  };
};

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL + '/graphql',
  headers: {
    'Apollo-Require-Preflight': 'true',
  },
});

const authLink = setContext((_, { headers }) => {
  let modifiedHeaders = headers || {};

  if (isAuthenticated()) {
    modifiedHeaders['Authorization'] = `Bearer ${getToken()}`;
  }

  return { headers: modifiedHeaders };
});

const client = new ApolloClient({
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    mutation: {
      fetchPolicy: 'no-cache',
    },
  },
  fetch,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          pagedLearningCampaigns: {
            keyArgs: ['activeStatus', 'filterEmail', 'sortField', 'sortOrder'],
            merge: makePaginationMerge('learningCampaigns'),
          },
          pagedInfBadgeTemplates: {
            keyArgs: ['filter'],
            merge: makePaginationMerge('infLearningTemplates'),
          },
          pagedInfLearningTemplates: {
            keyArgs: ['filter'],
            merge: makePaginationMerge('infLearningTemplates'),
          },
        },
      },
    },
    fragments: getAppFragments(),
  }),
  link: authLink.concat(httpLink),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>
);
