import React, { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { IconInput, InputWrapper } from 'components/Form';
import { SearchIcon } from 'components/Icons';
import { StyledUncontrolledTooltip as BaseTooltip } from 'components/Tooltips/SmallTooltip';

function CampaignEmailFilter({ disabled, setFilterEmail }) {
  const debounceSetFilterEmail = useCallback(debounce(setFilterEmail, 500), []);
  const onSearchChange = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }

    return debounceSetFilterEmail(e.target && e.target.value);
  };

  return (
    <>
      <BaseTooltip placement='bottom' target='search'>
        Enter an email address to find Learning Campaigns that include that Campaign Member
      </BaseTooltip>
      <InputWrapper>
        <label htmlFor='search'>Campaign Member Email</label>
        <IconInput
          id='search'
          name='q'
          type='text'
          onChange={onSearchChange}
          onKeyPress={onSearchChange}
          icon={SearchIcon}
          placeholder='Email Address'
          autocomplete='off'
          disabled={disabled}
        />
      </InputWrapper>
    </>
  );
}

CampaignEmailFilter.propTypes = {};

export default CampaignEmailFilter;
